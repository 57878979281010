body {
	height: 100%;
}

html {
	height: 100%;
}

#root {
	min-height: 100%;
}

.recl-button--variant_secondary {
	--t-plain: #fff;
	color: var(--g800) !important;
	box-shadow: inset 0 0 0 1px #8c8c8c !important;
}

.recl-checkbox__input {
	margin: 0 0.5rem 0 0;
}

.recl-checkbox__input:before {
	height: 1rem !important;
	width: 1rem !important;
	top: 0 !important;
	left: 0 !important;
}

.recl-checkbox__label {
	font-family: Marianne !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 14px !important;
	line-height: 22px !important;
	padding: 0 !important;
}

.recl-checkbox--isChecked.recl-checkbox:not([class*='checkbox--isIndeterminate']).recl-checkbox:not([class*='recl-checkbox--isDisabled'])
	.checkbox__input:before {
	background-color: var(--bf500) !important;
	border: 1px solid var(--bf500) !important;
}

.recl-field__input {
	font-family: Marianne !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 14px !important;
	line-height: 22px !important;
	box-shadow: none !important;
	background-color: #ffffff !important;
	height: 2rem !important;
	margin-top: 0 !important;
}

.recl-field:not(.recl-field--type_checkbox) .recl-field__input {
	border: 1px solid #8c8c8c !important;
	border-radius: 4px !important;
}

.recl-field__label {
	font-family: Marianne !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 22px !important;
	color: #262626 !important;
	margin-bottom: 0.5rem !important;
}

.recl-tag {
	padding: 0 0.25rem !important;
	min-height: 1.375rem !important;
	border-radius: 2px !important;
	width: fit-content !important;
	font-family: Marianne !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 14px !important;
	line-height: 22px !important;
}

.recl-tag--variant_inProgress {
	background-color: #ffe7ba !important;
	color: #873800 !important;
}

.recl-tag--variant_done {
	background-color: #d9f7be !important;
	color: #135200 !important;
}

.recl-icon--name_diplp-filter {
	--recl-icon-name: url('data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M8.18%2019.64c0%20.415.332.75.745.75h6.15a.747.747%200%2000.745-.75v-4.593H8.18zM20.627%203.61H3.373a.75.75%200%2000-.645%201.124l5.187%208.813h8.175l5.187-8.813a.753.753%200%2000-.65-1.125z%22%2F%3E%3C%2Fsvg%3E');
}

.recl-icon--name_diplp-gateway {
	--recl-icon-name: url('data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M21.75%209.188a.376.376%200%2000.375-.375V4.5a.376.376%200%2000-.375-.375h-4.313a.376.376%200%2000-.374.375v1.313H6.937V4.5a.376.376%200%2000-.375-.375H2.25a.376.376%200%2000-.375.375v4.313c0%20.206.169.375.375.375h1.312v5.625H2.25a.376.376%200%2000-.375.374V19.5c0%20.206.169.375.375.375h4.312a.376.376%200%2000.375-.375v-1.313h10.125V19.5c0%20.206.17.375.375.375h4.313a.376.376%200%2000.375-.375v-4.313a.376.376%200%2000-.375-.374h-1.313V9.188zm-3.188-3.563h2.063v2.063h-2.063zM3.376%207.688V5.625h2.062v2.063zm2.062%2010.687H3.375v-2.062h2.062zm15.188-2.062v2.062h-2.063v-2.062zm-1.875-1.5h-1.313a.376.376%200%2000-.374.375V16.5H6.937v-1.313a.376.376%200%2000-.375-.374H5.25V9.188h1.312a.376.376%200%2000.375-.375V7.5h10.125v1.313c0%20.206.17.375.375.375h1.313z%22%2F%3E%3C%2Fsvg%3E');
}

.recl-icon--name_diplp-info {
	--recl-icon-name: url('data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M10.5%205.25a1.5%201.5%200%20103%200%201.5%201.5%200%2000-3%200zm2.25%203.937h-1.5a.188.188%200%2000-.187.188V20.25c0%20.103.084.187.187.187h1.5a.188.188%200%2000.187-.187V9.375a.188.188%200%2000-.187-.188z%22%2F%3E%3C%2Fsvg%3E');
}

.recl-button__icon--name_diplp-filter {
	--recl-icon-name: url('data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M8.18%2019.64c0%20.415.332.75.745.75h6.15a.747.747%200%2000.745-.75v-4.593H8.18zM20.627%203.61H3.373a.75.75%200%2000-.645%201.124l5.187%208.813h8.175l5.187-8.813a.753.753%200%2000-.65-1.125z%22%2F%3E%3C%2Fsvg%3E');
}

.recl-button__icon--name_diplp-gateway {
	--recl-icon-name: url('data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M21.75%209.188a.376.376%200%2000.375-.375V4.5a.376.376%200%2000-.375-.375h-4.313a.376.376%200%2000-.374.375v1.313H6.937V4.5a.376.376%200%2000-.375-.375H2.25a.376.376%200%2000-.375.375v4.313c0%20.206.169.375.375.375h1.312v5.625H2.25a.376.376%200%2000-.375.374V19.5c0%20.206.169.375.375.375h4.312a.376.376%200%2000.375-.375v-1.313h10.125V19.5c0%20.206.17.375.375.375h4.313a.376.376%200%2000.375-.375v-4.313a.376.376%200%2000-.375-.374h-1.313V9.188zm-3.188-3.563h2.063v2.063h-2.063zM3.376%207.688V5.625h2.062v2.063zm2.062%2010.687H3.375v-2.062h2.062zm15.188-2.062v2.062h-2.063v-2.062zm-1.875-1.5h-1.313a.376.376%200%2000-.374.375V16.5H6.937v-1.313a.376.376%200%2000-.375-.374H5.25V9.188h1.312a.376.376%200%2000.375-.375V7.5h10.125v1.313c0%20.206.17.375.375.375h1.313z%22%2F%3E%3C%2Fsvg%3E');
}

.recl-button__icon--name_diplp-info {
	--recl-icon-name: url('data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M10.5%205.25a1.5%201.5%200%20103%200%201.5%201.5%200%2000-3%200zm2.25%203.937h-1.5a.188.188%200%2000-.187.188V20.25c0%20.103.084.187.187.187h1.5a.188.188%200%2000.187-.187V9.375a.188.188%200%2000-.187-.188z%22%2F%3E%3C%2Fsvg%3E');
}

.recl-button__secondaryIcon--name_diplp-filter {
	--recl-icon-name: url('data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M8.18%2019.64c0%20.415.332.75.745.75h6.15a.747.747%200%2000.745-.75v-4.593H8.18zM20.627%203.61H3.373a.75.75%200%2000-.645%201.124l5.187%208.813h8.175l5.187-8.813a.753.753%200%2000-.65-1.125z%22%2F%3E%3C%2Fsvg%3E');
}

.recl-button__secondaryIcon--name_diplp-gateway {
	--recl-icon-name: url('data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M21.75%209.188a.376.376%200%2000.375-.375V4.5a.376.376%200%2000-.375-.375h-4.313a.376.376%200%2000-.374.375v1.313H6.937V4.5a.376.376%200%2000-.375-.375H2.25a.376.376%200%2000-.375.375v4.313c0%20.206.169.375.375.375h1.312v5.625H2.25a.376.376%200%2000-.375.374V19.5c0%20.206.169.375.375.375h4.312a.376.376%200%2000.375-.375v-1.313h10.125V19.5c0%20.206.17.375.375.375h4.313a.376.376%200%2000.375-.375v-4.313a.376.376%200%2000-.375-.374h-1.313V9.188zm-3.188-3.563h2.063v2.063h-2.063zM3.376%207.688V5.625h2.062v2.063zm2.062%2010.687H3.375v-2.062h2.062zm15.188-2.062v2.062h-2.063v-2.062zm-1.875-1.5h-1.313a.376.376%200%2000-.374.375V16.5H6.937v-1.313a.376.376%200%2000-.375-.374H5.25V9.188h1.312a.376.376%200%2000.375-.375V7.5h10.125v1.313c0%20.206.17.375.375.375h1.313z%22%2F%3E%3C%2Fsvg%3E');
}

.recl-button__secondaryIcon--name_diplp-info {
	--recl-icon-name: url('data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M10.5%205.25a1.5%201.5%200%20103%200%201.5%201.5%200%2000-3%200zm2.25%203.937h-1.5a.188.188%200%2000-.187.188V20.25c0%20.103.084.187.187.187h1.5a.188.188%200%2000.187-.187V9.375a.188.188%200%2000-.187-.188z%22%2F%3E%3C%2Fsvg%3E');
}

@import-normalize;
